import React from 'react';
//gatsby
import { graphql } from 'gatsby';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Small from 'components/resources/small';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser"

//styled
const AuthorWrap = styled.section`
  position: relative;
`;

const AuthorHeader = styled.section`
  text-align: center;
  padding-top: ${rem('60px')};
  padding-bottom: ${rem('80px')};
  .img-wrap {
    width: ${rem('120px')};
    height: ${rem('120px')};
    border-radius: 50%;
    background-color: ${vars.colors.grey2};
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${rem('16px')};
    overflow: hidden;
    img {
      width: ${rem('120px')};
      height: ${rem('120px')};
      object-fit: cover;
      object-position: center;
    }
  }
  .description {
    margin-left: auto;
    margin-right: auto;
    max-width: ${rem('392px')};
    font-size: ${rem('18px')};
    line-height: 1.56;
  }
`;

const AuthorPosts = styled.section`
  .small-post {
    margin-bottom: ${rem('100px')};
    @media (max-width: ${vars.media.smMax}) {
      margin-bottom: ${rem('50px')};
    }
  }
`;

const AuthorCTA = styled.div`
  position: relative;
`;

const Author = ({ data }) => {

  const author = data.author;
  const articles = data.author.articles.nodes;

  return (
    <Layout isResources={true}>
      <Seo title={`${author.firstName} ${author.lastName} - Refersion`} />
      <AuthorWrap>
        <AuthorHeader>
          <Container>
            <div className="img-wrap">
              {author.avatar.url && <img src={author.avatar.url} alt={`${author.firstName} ${author.lastName}`} />}
            </div>
            <Heading heading3 marginBottom="8px">{`${author.firstName} ${author.lastName}`}</Heading>
            <p className="description">{author.description}</p>
          </Container>
        </AuthorHeader>
        <AuthorPosts>
          <Container>
            <Row>
              {articles.map((article) => (
                <Col col xs={12} sm={6} md={4} key={article.id}>
                  <Small post={article} withCategory />
                </Col>
              ))}
            </Row>
          </Container>
        </AuthorPosts>
        <AuthorCTA className="wp-front">
          {!!data.wp.blogCtas.articlesCTA && (
            parse(data.wp.blogCtas.articlesCTA)
          )}
        </AuthorCTA>
      </AuthorWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
query AuthorById( $id: String!) {
    author: wpUser(id: { eq: $id }) {
      id
      name
      description
      firstName
      lastName
      avatar {
        url
        foundAvatar
      }
      articles {
        nodes {
          id
          title
          uri
          excerpt
          nodeType
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 460, quality: 100)
                }
              }
            }
          }
          articleCategories {
            nodes {
              uri
              name
              id
            }
          }
        }
      }
    }
    wp {
      blogCtas {
        articlesCTA
      }
    }
  }
`

export default Author;